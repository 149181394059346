@import 'libs/styles/src/common/common';

@layer shared-component {
  .checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__input {
      width: 20px;
      height: 20px;
      accent-color: $blue-alt-1;
      margin: 0;
    }

    &__label {
      flex: 1;
      user-select: none;

      span {
        color: $dark-blue;
      }
    }
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
