@import 'libs/styles/src/common/common';

@layer override {
  .autocomplete-icon-color {
    color: var(--color);

    svg path {
      fill: var(--color);
    }
  }

  .autocomplete-input-color {
    color: var(--color);
  }

  .select-input-color {
    color: var(--color);

    &::after {
      content: ' ';
      border: solid var(--color);
      border-width: 0 2px 2px 0;
    }
  }
}

@layer page {
  .widget-menu {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;

    // display: flex;
    // align-content: flex-end !important;
    // flex-wrap: unset !important;
    &__links {
      // display: flex;
      // align-self: flex-end;
      text-align: center;
    }

    a {
      padding: 0.5rem;
    }
  }

  .wrapper {
    --fixed-header-size: 0;
  }

  .top-border {
    width: 100%;
    height: 24px;
    background-color: var(--color);
  }

  .card {
    &__header-distance {
      color: $blue;
    }
  }

  .results-wrapper {
    min-height: calc(100vh - var(--fixed-header-size));
  }

  .results__container {
    padding: 1rem;

    @include media-breakpoint-up(lg) {
      overflow-y: scroll; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
      height: calc(100vh - 20px - var(--fixed-header-size));
      min-height: 250px;
      overflow-x: hidden;
    }
  }

  .map__col {
    width: 50%;
    flex-basis: auto;
    flex-grow: 0;

    @include media-breakpoint-down(md) {
      height: 20vh;
      width: 100%;

      // margin-bottom: 1rem;
    }
  }

  .container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    &.no-gutters {
      max-width: unset;
      padding: 0;
    }

    @include media-breakpoint-down(md) {
      padding: 0;
      max-width: unset;

      .row {
        margin: 0;
      }

      .col {
        padding: 0;
      }
    }
  }

  .content-wrapper {
    max-width: 1600px;
    margin: auto;
  }

  .filters--override {
    background-color: var(--accent-color);
    padding: 1.5rem 3rem;

    @include media-breakpoint-down(md) {
      padding: 1rem;
      margin: 0 8px;
    }
  }

  .filters--more {
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.6rem;
  }

  .header__col {
    font-size: 1.125rem;

    .header {
      color: var(--color);
      font-size: 3.125rem;
      font-weight: normal;
      margin: 0.5rem 1rem;

      @include media-breakpoint-down(md) {
        font-size: 2.25rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1.75rem;
      }

      &.no-top-padding {
        padding-top: 0;
      }
    }

    .sub-heading {
      margin: 0.5rem 1rem 2rem;
      font-size: 2rem;

      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 1rem;
        font-size: 1.1rem;
      }
    }

    .pop-text {
      color: var(--color);
      font-size: 1.6875rem;
      font-weight: 600;

      @include media-breakpoint-down(md) {
        font-size: 1.35rem;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .hr {
      height: 2px;
      border: none;
      background-color: $hr-color;

      @include media-breakpoint-down(md) {
        margin: 0.5em 1rem;
      }
    }

    .search-box {
      display: flex;
      border-radius: 2rem;
      background-color: var(--accent-color);
      padding: 2rem;
      margin: 0 1rem 2rem;
      gap: 1rem;

      @include media-breakpoint-down(md) {
        padding: 1rem 2rem 2rem;
        margin: 0 1rem 1rem;
        flex-direction: column;
      }

      @include media-breakpoint-down(xs) {
        padding: 1rem 1rem 2rem;
        margin: 0 0.5rem 1rem;
      }

      .hr {
        background-color: $button-light-blue;
        margin: 1rem 0;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .search-form {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1 1;

        .search-form-fields {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }

      .powered-by {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        margin: 1rem 2rem 1rem 0;

        @include media-breakpoint-down(md) {
          align-items: center;
          margin: 1rem 0 0;
        }

        .icon {
          margin-bottom: 2rem;

          @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
          }
        }

        .text {
        }
      }
    }

    .icon-text {
      display: flex;
      align-items: center;
      margin: 0 2rem;

      .icon {
        margin: 1rem 0;
        margin-right: 1rem;

        img {
          width: 130px;
          height: auto;

          @include media-breakpoint-down(md) {
            width: 75px;
            height: auto;
          }
        }
      }

      .text {
      }
    }

    .search-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .search-button {
        width: 100%;

        &__content {
          background-color: var(--color);
        }
      }

      .search-terms {
        margin-top: 0.5rem;
        font-size: 0.875rem;
        text-align: right;

        > a {
          color: var(--color);
          text-decoration: underline;
          font-weight: bold;
        }
      }
    }
  }

  .footer {
    margin: 0.5rem 2rem 0;
    font-size: 0.875rem;

    a {
      color: var(--color);
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
